<template>
  <div wild-rift-second-reward>
    <div class="top">
      <em>상위 5팀</em>
      <h4>한국대표 선발전</h4>
      <p>진출 시드권</p>
    </div>
    <div class="bottom">
      <img class="card" src="/img/event/wariwari/Image/WR_Seed_card.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WariWariSecondReward',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wild-rift-second-reward] { .font-g;
  .top { .fs(28, 33.6px); .mt(16); .ls(-7%);
    > em { .bold; .c(#33c6ff);}
    > h4 { }
    > p { .bold; .c(#c89b3c);}
    &:after {.cnt; .h(8); .mt(12); .contain('/img/event/wariwari/WR_reward_line.svg'); .bg-w(293); .bg-c; .no-repeat;}
  }
  .bottom {
    .card { .wh(294, 133); .mt(143);}
  }
  @media (@tl-up) {
    .mh-c;
    .top { .fs(32, 38.4px);
      > em { .block; .mb(3);}
      > h4 { .ib; .mr(8);}
      > p { .ib;}
      &:after { .bg-w(408);}
    }
    .bottom {
      .card { .wh(492, 224); .mt(38);}
    }
  }
}
</style>
