<template>
  <div wild-rift-first-reward>
    <h4 class="title">총상금 <em>2,500만원</em></h4>
    <div class="reward-items">
      <div class="reward-top">
        <div class="reward-item">
          <div class="ranking">1위</div>
          <div class="prize-holder">
            <em>+</em> <b>1,000만</b>
          </div>
        </div>
      </div>
      <div class="reward-bottom">
        <div class="reward-item">
          <div class="ranking">2위</div>
          <div class="prize-holder">
            <em>+</em> <b>400만</b>
          </div>
        </div>
        <div class="reward-item">
          <div class="ranking">3위</div>
          <div class="prize-holder">
            <em>+</em> <b>200만</b>
          </div>
        </div>
        <div class="reward-item">
          <div class="ranking">4위</div>
          <div class="prize-holder">
            <em>+</em> <b>100만</b>
          </div>
        </div>
        <div class="reward-item">
          <p>8강 진출팀 모두</p>
          <b>100만</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WariWariFirstReward',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[wild-rift-first-reward] { .w(100%);
  .title { .fs(28, 33.6px); .font-g; .c(white); .tc; .mt(16); .ls(-7%);
    > em { .c(#33c6ff);}
    &::after { content: ''; .block; .h(8); .mt(12); .contain('/img/event/wariwari/WR_reward_line.svg'); .bg-w(293); .bg-c; .no-repeat;}
  }
  .line { .wh(294, 8);}
  .reward-items { .max-w(293); .mh-c; .mt(30);
    .reward-item {.wh(293, 100); .bgc(#363840); .br(4); .mb(12); .font-g; .tc;
      .ranking { .ib; .wh(60, 32); .bgc(#a38e40); .br(20); .fs(16, 34px); vertical-align: text-bottom;}
      .prize-holder { .ib;
        > em { .c(#a38e40); .fs(32, 32px); .ib; .m(0, 12); .ls(-7%);}
        > b { .fs(36, 100px); .bold; .ls(-7%);}
      }
    }
    .reward-top {
      &::after { content: ''; .hide; .h(36); .contain('/img/event/wariwari/WR_line.svg'); .bg-w(370); .bg-c; .no-repeat; }
    }
    .reward-bottom {
      .reward-item {
        &:nth-of-type(4) {.hide;}
      }
    }
  }
  @media (@tl-up) {
    .title { .fs(32, 38.4px);
      &::after { .bg-w(328);}
    }
    .line { .wh(328, 8); }
    .reward-items { max-width: 486px; .mh-c;
      .reward-top {
        &::after { .block; }
        .reward-item { .mh-c; .mb(0);
          .ranking { .fs(18, 32px);}
        }
      }
      .middle-line { .block;}
      .reward-bottom { .crop;
        .reward-item { .wh(154, 100); .fl;
          &:nth-of-type(1) { .p(20, 0, 18, 0);}
          &:nth-of-type(2) { .m(0, 12); .p(20, 0, 18, 0);}
          &:nth-of-type(3) { .p(20, 0, 18, 5);}
          &:nth-of-type(4) {.block; .wh(486, 60); .mb(0);
            > p { .fs(14, 60px); .font-g; .ib; .mr(12);}
            > b { .fs(20, 60px); .bold; .font-g;}
          }
          .ranking {
            .wh(50, 24); .mb(10); .fs(14, 24px);
          }
          .prize-holder {
            > b { .fs(28, 28px); }
            > em { .ml(0); .fs(32, 32px);}
          }
        }
      }
    }
  }
}
</style>
