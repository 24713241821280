<template>
  <div wild-rift-rewards>
    <OfficialLeagueGameInfoTemplate :title="title" :slides="slides" />
  </div>
</template>

<script>
import OfficialLeagueGameInfoTemplate from '@/views/components/gameHome/OfficialLeagueGameInfoTemplate.vue';
import WariWariFirstReward from '@/views/components/gameHome/wr/gameInfo/slides/WariWariFirstReward.vue';
import WariWariSecondReward from '@/views/components/gameHome/wr/gameInfo/slides/WariWariSecondReward.vue';
import WariWariMvpReward from '@/views/components/gameHome/wr/gameInfo/slides/WariWariMvpReward.vue';

export default {
  name: 'WariWariRewards',
  components: { OfficialLeagueGameInfoTemplate },
  data() {
    return {
      title: {
        title: 'REWARD',
        titleKo: '대회 보상',
        subTitle:
          '총상금 2,500만원과 한국대표 선발전 진출 시드권을 잡아라!',
      },
      slides: [WariWariFirstReward, WariWariSecondReward, WariWariMvpReward],
    };
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wild-rift-rewards] {
  [item-reword-slider] { .mt(39); .mb(54); }
  @media (@tl-up) {
    [item-reword-slider] { .min-h(496); .mt(40); .mb(108); .pb(20); }
  }
  @media (@ds-up) {
    [item-reword-slider] { .mb(0); }
  }
}
</style>
