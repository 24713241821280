<template>
  <div wari-wari-mvp-reward>
    <h4 class="title">우승팀 <em>MVP</em></h4>
    <div class="reward-holder">
      <div class="reward-item">
        <h6>카카오웹툰<em>50만 캐시</em></h6>
        <img src="/img/event/wariwari/Image/kakao-webtoon-reward.svg" alt="kakao webtoon reward">
      </div>
      <div class="reward-item">
        <h6>LCK 포토카드<em>1만원권 50장</em></h6>
        <img src="/img/event/wariwari/Image/lck-photo-card-reward.svg" alt="LCK photo card reward">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WariWariMvpReward',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[wari-wari-mvp-reward] {
  .title { .fs(28, 33.6px); .font-g; .c(white); .tc; .mt(16); .mb(32); .ls(-7%);
    > em { .c(#33c6ff);}
    &::after { content: ''; .block; .h(8); .mt(12); .contain('/img/event/wariwari/WR_reward_line.svg'); .bg-w(300); .bg-c; .no-repeat;}
  }

  .reward-holder { .max-w(300); .mh-c; .contain('/img/event/wariwari/Vector/WR-plus-blue.svg'); .bg-s(32, 32); .bg-c;
    .reward-item { .bgc(#363840); .br(4);
      &:first-child { .mb(56); .pt(32); .pb(26);
        > h6 { .mb(20); }
      }
      &:last-child { .pt(25); .pb(18);
        > h6 { .mb(11); }
      }

      > h6 { .fs(24, 29); .c(#fff); .ls(-1); .font-g;
        > em { .block; .c(#a38e40); }
      }
    }
  }

  @media (@tl-up) {
    .title { .fs(32, 38.4px);
      &::after { .bg-w(328);}
    }

    .reward-holder { .max-w(500); .crop;
      .reward-item { .wh(210, 308);
        &:first-child { .fl; .mb(0); .pt(52); .pb(69);
          > h6 { .mb(37); }
        }
        &:last-child { .fr; .pt(52); .pb(55);
          > h6 { .mb(28); }
        }

        > h6 { .fs(28, 34); }
      }
    }
  }
}
</style>
